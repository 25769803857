import React from "react";
import { useParams } from "react-router-dom";
import Thumb from './Thumb';
//config
import {POSTER_SIZE, BACKDROP_SIZE,IMAGE_BASE_URL} from '../config';

//compoents
import BreadCrumb from "./BreadCrumb";
import Spinner from "./Spinner";
import Grid from "./Grid";

//hook
import { usePersonFetch } from "../hooks/usePersonFetch";
import PersonInfo from "./PersonInfo";

//Image
import NoImage from '../images/no_image.jpg';

import {SortActorFilmReleaseDate} from '../helpers';

const Person = () => {
    const {actorId} =useParams();
    const {state:person,loading,error} = usePersonFetch(actorId);
    const headerText="Movie";
    //sort by Cast.release_date

    //person.movies.sort(SortActorFilmReleaseDate);
    //person.movies.sort((a,b)=>{
    //    return a.release_date- b.release_date;
    //});
    //console.log(movie);
    //const movies = person.movies;
    //movies.sort(function(a,b){
    //    return a.release_date- b.release_date;
    //});

    //console.log(person.movies);
    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    return (
        <>
            <BreadCrumb movieTitle={person.name} />
            <PersonInfo person={person} />
            <Grid header={person.movies.length>1 ?headerText+'s':headerText}>
                {
                person.movies.map(movie=>(
                    <Thumb
                    key={movie.id}
                    clickable
                    image={
                        movie.poster_path?IMAGE_BASE_URL+POSTER_SIZE+movie.poster_path:NoImage
                    }
                    movieId={movie.id}
                    thumbFooter={movie.release_date}
                    />

                ))}

            </Grid>
        </>
    );
};

export default Person;