import { number } from "prop-types";

// Convert time to hours and minutes
export const calcTime = time => {
  const hours = Math.floor(time / 60);
  const mins = time % 60;
  return `${hours}h ${mins}m`;
};
// Convert a number to money formatting
export const convertMoney = money => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return money>0?formatter.format(money):'N/A';
};

export const isPersistedState =stateName =>{
  const sessionState = sessionStorage.getItem(stateName);
  return sessionState && JSON.parse(sessionState);
};

//convert yyyy-mm-dd string to age
export const calcAge =birthday=>{
  const today = new Date();
  const birthDate = new Date(birthday);
  var age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export const roundNum=num=>{
    return num?Math.round(num*100)/100:'N/A';
}

export const SortActorFilmReleaseDate=(a,b)=>{
  //return new Date(a.cast.release_date)
  //return b.cast.release_date > a.cast.release_date;
  return b.release_date > a.release_date;
}