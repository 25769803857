//import { click } from "@testing-library/user-event/dist/click";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
//styles
import { Wrapper,Image } from "./Thumb.styles";

const Thumb = ({image,movieId, clickable,thumbFooter}) =>(
    <Wrapper>
        {clickable ?(
            <Link to={`/movie/${movieId}`}>
                <Image src={image} alt='movie-thumb' />
            </Link>
            
        ):(
            <Image src={image} alt='movie-thumb' />
        )}
        <p>{thumbFooter?thumbFooter.substring(0,4):'N/A'}</p>
    </Wrapper>
)

Thumb.propTypes={
    image:PropTypes.string,
    movieId:PropTypes.number,
    clickable:PropTypes.bool
};

export default Thumb;