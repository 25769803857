import React from "react";
import PropTypes from 'prop-types';
//components
import Thumb from "../Thumb";
import {calcAge} from '../../helpers';

//config
import { IMAGE_BASE_URL,POSTER_SIZE } from "../../config";

//Image
import NoImage from '../../images/no_image.jpg';

//styles
import { Wrapper,Content,Text } from "./PersonInfo.styles";

const PersonInfo =({person}) =>(
    <Wrapper>
        <Content>
            <Thumb 
                image={
                    person.profile_path
                    ?`${IMAGE_BASE_URL}${POSTER_SIZE}${person.profile_path}`
                    :NoImage
                }
                clickable ={false}
                thumbFooter={' '}
            />
            <Text>
                <h1><a href={person.homepage} target='_blank'>{person.name}</a>
                </h1>
                <h4>Birthday: {person.birthday} (Age {calcAge(person.birthday)})</h4>
                <h4>Birth place: {person.place_of_birth}</h4>
                <h3>BIO</h3>
                <p>{person.biography?person.biography:'N/A'}</p>
            </Text>
        </Content>
    </Wrapper>
)

//PropTypes.shape


export default PersonInfo;