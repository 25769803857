import React from 'react';
//Routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//Components
import Header from './components/Header';
import Home from './components/Home';
import Movie from './components/Movie';
import Person from './components/Person';
import NotFound from './components/NotFound';
import Crew from './components/Crew';

//styles
import {GlobalStyle} from './GlobalStyle';

/*
function App(){ 
    return (
      <div className='App'>
        <Header />
        <Home />
        <GlobalStyle />
      </div>
    );

}
*/
const App=() => (  
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/movie/:movieId' element={<Movie />} />
        <Route path='/person/:actorId' element={<Person />} />
        <Route path='/director/:actorId' element={<Crew />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
      <GlobalStyle />
    </Router>

);

export default App;
