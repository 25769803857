import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import App from './App';

//ref:https://bobbyhadz.com/blog/react-reacdom-render-no-longer-supported-in-react-18#:~:text=The%20error%20%22ReactDOM.render%20is%20no%20longer%20supported%20in,root%20element%20and%20use%20the%20ReactDOMClient.render%20method%20instead.
const rootElement =document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App/>);

//root.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>,
//);
