import { useState,useEffect } from "react";
import API from '../API';
//helpers
import { isPersistedState } from "../helpers";
const initialState ={
    page:0,
    results:[],
    total_pages:0,
    total_results:0
}
export const useCrewFetch=actorId =>{
    const [state,setState] = useState(initialState);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(false);

    useEffect(()=>{
        const fetchCombinedCredits =async() =>{
            try{
                setLoading(true);
                setError(false);
                const person = await API.fetchPerson(actorId);
                const combined_credits = await API.fetchCombinedCredits(actorId);
                //const personMovies = await API.fetchCombinedCredits(actorId);
                //const credits = await API.fetchCredits(actorId);
                //Get directors only
                //const directors = credits.crew.filter(
                //    member=>member.job==='Director'
                //);
                var directed_movies = combined_credits.crew.filter(
                    member=>member.job==='Director'
                );
                directed_movies.sort((a,b)=>{
                    var aDate = new Date(a.release_date?a.release_date:"1950-01-01");
                    var bDate = new Date(b.release_date?b.release_date:"1950-01-01");                        
                    return bDate - aDate;
                });
                setState({
                    ...person,
                    movies: directed_movies
                })
    
                setLoading(false);
    
            }catch(error){
                setError(true);
            }
        };

        const sessionState = isPersistedState(actorId);
        if(sessionState){
            setState(sessionState);
            setLoading(false);
            return;
        }

        fetchCombinedCredits();
    },[actorId]);

    //write to sessionStorage
    useEffect(()=>{
        sessionStorage.setItem(actorId,JSON.stringify(state));
    },[actorId,state]);

    return { state,loading,error };
}