import React from "react";
import { useParams } from "react-router-dom";
//config
import { IMAGE_BASE_URL,PAGE_SIZE, POSTER_SIZE } from "../config";

//compoents
import BreadCrumb from "./BreadCrumb";
import Grid from "./Grid";
import Spinner from "./Spinner";
import MovieInfo from "./MovieInfo";
import MovieInfoBar from "./MovieInfoBar";
import Actor from "./Actor";


//hook
import { useMovieFetch } from "../hooks/useMovieFetch";

//Image
import NoImage from '../images/no_image.jpg';


const Movie = () => {
    const {movieId} =useParams();

    const {state:movie,loading,error} = useMovieFetch(movieId);
    console.log(movie);
    //console.log(movie);
    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    return (
        <>
            <BreadCrumb movieTitle={movie.original_title} />
            <MovieInfo movie={movie} />
            <MovieInfoBar 
                time={movie.runtime} 
                budget={movie.budget} 
                revenue={movie.revenue}
            />
            <Grid header='Actors'>
                {movie.actors.map(actor=>(
                    <Actor 
                        key={actor.credit_id}
                        name={actor.name}
                        actorId={actor.id}
                        character ={actor.character}                        
                        imageUrl ={
                            actor.profile_path
                            ? `${IMAGE_BASE_URL}${POSTER_SIZE}${actor.profile_path}`
                            :NoImage
                        }
                        />
                ))}
            </Grid>
        </>
    );
};

export default Movie;