import { useState,useEffect } from "react";
import API from '../API';
//helpers
import { isPersistedState } from "../helpers";
const initialState ={
    page:0,
    results:[],
    total_pages:0,
    total_results:0
}
export const usePersonFetch=actorId =>{
    const [state,setState] = useState(initialState);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(false);

    useEffect(()=>{
        const fetchPerson =async() =>{
            try{
                setLoading(true);
                setError(false);
    
                const person = await API.fetchPerson(actorId);
                const personMovies = await API.fetchPersonMovies(actorId);
                //const credits = await API.fetchCredits(actorId);
                //Get directors only
                //const directors = credits.crew.filter(
                //    member=>member.job==='Director'
                //);
                
                setState({
                    ...person,
                    //movies: personMovies.cast
                    movies: personMovies.cast.sort((a,b)=>{
                        //console.log(new Date(a.release_date).getTime() - new Date(b.release_date).getTime());
                        //return a.release_date>b.release_date;
                        var aDate = new Date(a.release_date?a.release_date:"1950-01-01");
                        var bDate = new Date(b.release_date?b.release_date:"1950-01-01");
                        //console.log("A:"+aDate);
                        //console.log("B:"+bDate);
                        return bDate - aDate;
                        //return new Date(a.release_date).getTime() - new Date(b.release_date).getTime();
                    })
                })
    
                setLoading(false);
    
            }catch(error){
                setError(true);
            }
        };

        const sessionState = isPersistedState(actorId);
        if(sessionState){
            setState(sessionState);
            setLoading(false);
            return;
        }

        fetchPerson();
    },[actorId]);

    //write to sessionStorage
    useEffect(()=>{
        sessionStorage.setItem(actorId,JSON.stringify(state));
    },[actorId,state]);

    return { state,loading,error };
}