import React from "react";
import PropTypes from 'prop-types';
//components
import Thumb from "../Thumb";
import {roundNum} from '../../helpers';
//config
import { IMAGE_BASE_URL,POSTER_SIZE } from "../../config";

//Image
import NoImage from '../../images/no_image.jpg';

//styles
import { Wrapper,Content,Text } from "./MovieInfo.styles";
import { Link } from "react-router-dom";

const MovieInfo =({movie}) =>(
    
    <Wrapper backdrop ={movie.backdrop_path}>

        <Content>
            <Thumb 
                image={
                    movie.poster_path
                    ?`${IMAGE_BASE_URL}${POSTER_SIZE}${movie.poster_path}`
                    :NoImage                    
                }
                clickable ={false}
                thumbFooter={' '}
            />
            <Text>
                <h1>{movie.title}</h1>
                <h6>Production by: {movie.production_companies.map(company=>(
                    <span key={company.id}>{company.name+(company.origin_country?'('+company.origin_country+')':company.origin_country)+' '}</span>
                ))}</h6>
                <h6>Relase date: {movie.release_date} ({movie.status})</h6>
                <h3>GENRES</h3>
                <p>{movie.genres.map(genre=>(
                    <span key={genre.id}>{genre.name+' '}</span>
                ))}</p>
                <h3>PLOT</h3>
                <p>{movie.overview}</p>

                <div className="rating-directors">
                    <div>
                        <h3>RATING</h3>
                        <div className="score">{roundNum(movie.vote_average)}</div>
                    </div>
                    <div className="director">
                        <h3>DIRECTOR{movie.directors.length>1 ?'S':''}</h3>
                        {movie.directors.map(director=>(
                            <Link to={`/director/${director.id}`} key={director.credit_id}><p key={director.credit_id}>{director.name}</p></Link>
                        ))}
                    </div>
                    <div className="director">
                        <h3>WRITER{movie.Writers.length>1 ?'S':''}</h3>
                        {movie.Writers.length>0?movie.Writers.map(writer=>(
                            <p key={writer.id}>{writer.name}</p>
                        )):'N/A'}
                    </div>
                </div>
            </Text>
        </Content>
    </Wrapper>
)

//PropTypes.shape
MovieInfo.propTypes ={
    movie:PropTypes.object
}

export default MovieInfo;