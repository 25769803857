import styled from "styled-components";
import {IMAGE_BASE_URL,BACKDROP_SIZE} from '../../config'

export const Wrapper = styled.div`
    background: ${({backdrop}) =>
        backdrop
        ?`url(${IMAGE_BASE_URL}${BACKDROP_SIZE}${backdrop})`
        : '#000'};
    background-size: cover;
    background-position: center;
    padding: 40px 20px;
    animation: alternateMovieInfo 1s;

    @keyframes alternateMovieInfo {
        frmo{
            opacity: 0;
        }to{
            opacity: 1;
        }
    }
`;

export const Content= styled.div`
    display: flex;
    max-width: var(--maxWidth);
    margin: 0 auto;
    background: rgba(0,0,0,0.7);
    border-radius: 20px;

    @media screen and (max-width:768px){
        display: block;
        max-height: none;
        
    }
`;

export const Text = styled.div`
    width: 100%;
    padding: 0px 40px;
    color : var(--white);
    overflow:hidden;

    a:link { text-decoration: none; color: var(--white);}


    a:visited { text-decoration: none;color: var(--white);}


    a:hover { text-decoration: none; color: var(--white);}


    a:active { text-decoration: none;color: var(--white); }


    h1{
        @media screen and (max-width:768px){
            font-size: var(--fontBig);
            color: var(--white);
        }
    }
`;